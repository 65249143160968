import React, { Component } from 'react'

import painting from "../../assets/About/DH_studio_b.jpg"

export class About extends Component {
    render() {
        return (
            <div className="col-md-8 col-sm-12 pt-4 mr-auto" id="infoWrapper">

                <img src={painting} id="aboutImage" className="paintingImage pt-2" alt="Dan Hofstadter Studio" />
                <div className="indent baskerville aboutText">
                My studio is situated in New York state, about two and a half hours
north of New York City. </div>

                <div className="indent baskerville aboutText">As a teenager I studied at the Brooklyn Museum
                Art School and later at Columbia University. Moving to Paris, then
                Sweden, I had my first show in Stockholm with the dealer Eero Solin. I
                am always painting, and most recently I have had two shows, comprising
                more than 50 pieces altogether, at the Atlantic Works Gallery in Boston,
                a city I visit regularly. My current work, which can be large or small, lies
                between color abstraction and a kind of nature imagery.</div>
                <div className="indent baskerville mb-5 aboutText">
                I welcome visits to my studio, which can be arranged by appointment. To see more paintings or to inquire about prices, contact me at dhworks5012@gmail.com.
                                </div>

                <div className="col-md-2 d-none d-sm-block"></div>


            </div>
        )
    }
}

export default About
