export const LargeAbstractions = [
    require("./DH1;50x58;.jpeg"),
    require("./DH2;54x66;.jpg"),
    require("./DH3;56x52;.jpg"),
    require("./DH8;50x54;.jpg"),
    require("./DH16;51x66;.jpg"),
    require("./DH18;60x50;.jpeg"),
    require("./DH19;40x56;.jpeg"),
    require("./Chalice;23x42 - Oil;.jpg")
]
export default LargeAbstractions